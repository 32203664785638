.full-width-carousel {
  width: 100%;
  margin-top: 0px;
}

.carousel-image-container {
  height: 400px;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.full-width-carousel .carousel-caption {
  bottom: 1rem;
  z-index: 10;
  text-align: center;
}

.full-width-carousel .carousel-caption h3 {
  font-size: 2rem;
  font-weight: bold;
}

.carousel-button {
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .carousel-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.marketing-section {
  padding: 0px 0;
}

.marketing-title {
  font-size: 2rem; /* Adjust to your desired size */
  font-family: 'Styrene Regular';
  text-align: center; /* Horizontally centers the text */
}

.marketing-text {
  font-size: 1rem;
}

.feature-title {
  font-size: 1.3rem;
}

.custom-bg-color {
  background-color: #f8f9fa;
}

.carousel-title,
.feature-title,
.card-title,
.modal-title {
  font-family: 'Styrene Bold', sans-serif;
}


.card-title {
  font-family: 'Styrene Bold', sans-serif;
  font-size: 1.6rem;
  text-align: center;
}

.carousel-text,
.marketing-text,
.feature-text,
.card-text,
.modal-text {
  font-family: 'Tiempos Regular', serif;
  font-size: 1.2rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.custom-col {
  padding-left: 15px;
  padding-right: 15px;
}
