/* TextSession.css */
.chat-card {
  background-color: #f4f3ee;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-body {
  background-color: #f4f3ee;
  padding: 15px;
  overflow-y: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.chat-body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.chat-bubble {
  padding: 8px;
  border-radius: 12px;
  max-width: 80%;
  margin-bottom: 12px;
  position: relative;
}

.user-bubble {
  background-color: #4E91F0;
  color: #ffffff;
  align-self: flex-end;
}

.assistant-bubble {
  background-color: #E5E5EA;
  color: #000000;
  align-self: flex-start;
}

.bubble-arrow {
  position: absolute;
  bottom: -6px;
  width: 0;
  height: 0;
  border-style: solid;
}

.user-bubble .bubble-arrow {
  right: 10px;
  border-width: 6px 0 0 6px;
  border-color: #4E91F0 transparent transparent transparent;
}

.assistant-bubble .bubble-arrow {
  left: 10px;
  border-width: 6px 6px 0 0;
  border-color: #E5E5EA transparent transparent transparent;
}

.chat-footer {
  background-color: #f4f3ee;
  padding: 10px;
  display: flex;
  align-items: center;
}

.message-input {
  flex: 1;
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-right: 0px;
}

.send-button {
  color: #007bff;
  margin-right: 0px;
  padding: 10px;
}