/* Global styles */
body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Tap2Earn specific styles */
.tap2earn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}

.tap2earn-container h1 {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 20px;
}

#user-info p {
    font-size: 1.2rem;
    margin: 10px 0;
}

button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1.2rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

#message {
    margin-top: 20px;
    font-size: 1rem;
    text-align: center;
}
